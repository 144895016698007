// import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./views/Home";
import Register from "./views/Register";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import Layout from "./views/Layout";
import ErrorPage from "./views/ErrorPage";
import Tournaments from "./views/Tournaments";
import Otp from "./views/Otp";
import PasswordReset from "./views/PasswordReset";
import { Test } from "./views/Test";
import { PlayersPage } from "./views/PlayersPage";
import { TournamentRegister } from "./views/TournamentRegister";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "forgot-password/otp",
        element: <Otp />,
      },
      {
        path: "forgot-password/otp/reset",
        element: <PasswordReset />,
      },
      {
        path: "tournamnet-register",
        element: <Tournaments />,
      },
      {
        path: "player-pool",
        element: <PlayersPage />,
      },
      {
        path: "tournamnet-register/:id",
        element: <TournamentRegister />,
      },
      {
        path: "test",
        element: <Test />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
