import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <section
      className="container bg-gradient-to-t from-red-600 to-blue-900 flex align-middle justify-center bg-no-repeat bg-cover w-full min-h-screen pt-10 pb-20 bg-blend-color-dodge"
      // style={{ backgroundImage: "url(" + loginimg + ")" }}
    >
      <div className="w-[450px] h-fit shadow-xl p-5 gap-4 flex flex-col bg-white">
        <h1 className="text-xl font-bold">Password Reset</h1>
        <form action="" className="">
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
            placeholder="email"
            required
          />

          <button
            type="button"
            class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 mt-4 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-1.5 text-center me-2 mb-2"
          >
            <Link to={"otp"}>Send otp</Link>
          </button>
        </form>
        <span></span>
      </div>
    </section>
  );
};
export default ForgotPassword;
