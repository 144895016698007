import React from "react";
import sectio2 from "../assets/bg2.jpg";
import Card from "../components/Card";
export const PlayersPage = () => {
  return (
    <section
      className=" bg-blue-700 bg-no-repeat bg-cover bg-top  min-h-screen overflow-hidden bg-blend-luminosity mb-5 shadow-2xl"
      style={{ backgroundImage: "url(" + sectio2 + ")" }}
    >
      <div className="container max-w-screen-xl align-middle p-8 mx-auto flex  flex-col ">
        <div className="flex flex-col w-auto align-middle justify-center  p-4 mx-auto">
          <h1 className=" flex max-w-2xl mb-4 text-3xl font-extrabold tracking-tight align-middle justify-center leading-none md:text-4xl xl:text-5xl text-slate-600">
            Player Pool
          </h1>
          <p className="text-slate-800">
            Meet the Newly Promoted Players Making Waves in the Game
          </p>
        </div>
        <div className="flex flex-row w-auto flex-wrap max-w-screen-lg">
          <Card />
        </div>
      </div>
    </section>
  );
};
