import { useState } from "react";
import cock from "../assets/bad.svg";
const Tournament = () => {
  const [showfull, setShowfull] = useState(false);
  let discription = ` Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament
              (U-15 & U-17) Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament`;
  if (!showfull) {
    discription = discription.substring(0, 90) + `...`;
  }
  return (
    <>
      <div className="flex flex-row  flex-wrap lg:flex-nowrap h-auto mb-1">
        <div className="relative  w-[150px] h-auto">
          <span className="absolute bottom-0 right-1/2 w-1 lg:h-1/2  z-0  bg-red-600"></span>
          <div className="flex z-2 flex-col  align-middle justify-center rounded-lg mr-5 bg-red-600 w-[150px] min-h-[50%] ">
            <span className="flex  align-middle justify-center text-white font-bold ">
              11-17
            </span>
            <span className="text-white text-xs flex  align-middle justify-center">
              june
            </span>
          </div>
        </div>
        <div className="flex flex-row bg-white  rounded-lg">
          <div className="flex w-[100px]  p-1 h-full align-middle justify-center ">
            <img src={cock} alt="svg" className="w-full" l />
          </div>
          <div className="p-5 shadow-lg ">
            <span className="text-green-600 text-sm mb-1">
              10 Jun-17 Jun 2024
            </span>
            <h1 className="font-semibold text-sm mb-1">{discription}</h1>
            <button onClick={() => setShowfull((e) => !e)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 text-green-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
            <div className="flex flex-row align-middle justify-between w-full">
              <span className="text-slate-400 text-xs">Hyderabad, TS</span>
              <span className=" bg-blue-900 text-white rounded-full pr-4 pl-4 pt-1 pb-1 shadow-lg hover:translate-x-1 cursor-pointer">
                Register
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tournament;
