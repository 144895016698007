import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  increment,
  decrement,
  reset,
  valueIncrement,
} from "../slices/api/countSlice";

const Error_msg = "Age of applicant should be minimum 18 !";
const useDebounce = (text, delay) => {
  const [debounce, setDebounce] = useState(text);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounce(text);
    }, delay);
    return () => clearTimeout(timer);
  }, [text, delay]);
  return debounce;
};
export const Test = () => {
  const fruit = ["apple", "mango", "pinapple", "orange", "mango"];
  const [searchValue, setsearchValue] = useState("");
  const [text, setText] = useState();
  const [fruitData, setfruitData] = useState(fruit);
  const filteredData = fruitData.filter((e) => {
    return e.toLowerCase().includes(searchValue.toLowerCase());
  });
  const setDeboounce = useDebounce(searchValue, 1000);
  const count = useSelector((state) => state.counter.count);
  const dispatch = useDispatch();
  return (
    <div className="container flex flex-col items-center">
      <h1 className="font-bold text-2xl text-red-600">Test</h1>
      <input
        type="search"
        name="search"
        placeholder="search here..."
        id="search"
        className="border border-gray-400"
        onChange={(e) => setsearchValue(e.target.value)}
      />
      <p className="text-gray-500">
        data entered: <span className="text-blue-200"> {setDeboounce}</span>
      </p>
      {filteredData.map((e) => {
        return <p>{e}</p>;
      })}
      <div>{count}</div>
      <button onClick={() => dispatch(increment())}>+</button>
      <button onClick={() => dispatch(decrement())}>-</button>
      <button onClick={() => dispatch(reset())}>reset</button>
      <button onClick={() => dispatch(valueIncrement(10))}>
        increment-ten
      </button>
      <input
        type="number"
        className="border text-black border-gray-500 mb-5"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      {text < 18 && text ? (
        <p className="text-red-700 p-2 border border-red-500 mb-5 border-r-8">
          {Error_msg}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};
