const Register = () => {
  return (
    <>
      <section className="container bg-gradient-to-t from-red-600 to-blue-900 flex align-middle justify-center bg-no-repeat bg-cover w-full min-h-screen pt-10 pb-20 bg-blend-color-dodge">
        <div className=" bg-white w-[450px] h-fit shadow-xl p-5 gap-4 flex flex-col">
          <h1 className="text-xl font-bold">Register</h1>
          <form action="" className="">
            <input
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="Name"
              required
            />
            <input
              type="email"
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="email"
              required
            />
            <select
              id="gender"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
            >
              <option selected>Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
            <input
              datepicker
              type="date"
              name="dob"
              id="dob"
              className="block w-full rounded-md border-0 py-1.5 pl-7 mb-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="DOB"
              required
            />
            <input
              type="tel"
              name="phno"
              pattern="[+]{1}[0-9]{11,14}"
              id="price"
              className="block w-full rounded-md border-0 py-1.5 pl-7 mb-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="Phone no"
              required
            />
            <select
              id="category"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
            >
              <option selected>Category</option>
              <option value="A">A Level</option>
              <option value="B">B Level</option>
              <option value="C">C Level</option>
              <option value="M">Masters</option>
            </select>
            <select
              id="club"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
            >
              <option selected>Club</option>
              <option value="M">Alpha Nada Vayal</option>
              <option value="F">Arrow Mepaddi</option>
            </select>
            <input
              type="id"
              name="text"
              id="kbsaid"
              className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="KBSAID"
              required
            />
            <input
              type="text"
              name="place"
              id="place"
              className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="Place"
              required
            />
            <select
              id="state"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
            >
              <option selected>Choose state</option>
              <option value="US">Kerala</option>
              <option value="CA">Tamilnadu</option>
              <option value="FR">karnataka</option>
              <option value="DE">others</option>
            </select>
            <select
              id="country"
              class="bg-gray-50 border border-gray-300 mb-4 text-gray-900 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
            >
              <option selected>Choose country</option>
              <option value="US">India</option>
              <option value="CA">others</option>
            </select>
            <input
              type="password"
              name="password"
              pattern="/^[a-zA-Z0-9!@#\$%\^\&*_=+-]{8,12}$/g"
              id="password"
              className="block w-full rounded-md border-0 py-1.5 pl-7 mb-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="Password"
              required
            />
            <input
              type="password"
              name="password"
              pattern="/^[a-zA-Z0-9!@#\$%\^\&*_=+-]{8,12}$/g"
              id="password"
              className="block w-full rounded-md border-0 py-1.5 pl-7 mb-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
              placeholder="New Password"
              required
            />
            <button
              type="button"
              class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 mt-4 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-1.5 text-center me-2 mb-2"
            >
              Register
            </button>
          </form>
        </div>
      </section>
    </>
  );
};
export default Register;
