import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/logo.png";

import "../css/navbar.css";

export const Menu = () => {
  return (
    <div className="flex flex-col mt-4 space-y-2 lg:-mx-6 lg:mt-0 lg:flex-row lg:space-y-0  p-2 ">
      <NavLink
        className={({ isActive }) => {
          return isActive
            ? "text-gray-300  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs"
            : "text-gray-700  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs";
        }}
        to="/"
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) => {
          return isActive
            ? "text-gray-300  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs"
            : "text-gray-700  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs";
        }}
        to="/player-pool"
      >
        Player's
      </NavLink>
      <NavLink
        className={({ isActive }) => {
          return isActive
            ? "text-gray-300  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs"
            : "text-gray-700  lg:px-6 dark:hover:text-blue-800 hover:text-blue-800 text-xs";
        }}
        to="/tournamnet-register"
      >
        Tournament Registration
      </NavLink>
      <NavLink
        className="text-white mr-1 rounded-full bg-blue-900   lg:px-6 dark:hover:text-red-400 hover:text-red-500 text-xs"
        to="/login"
      >
        Login
      </NavLink>
      <NavLink
        className="text-white rounded-full bg-red-600  lg:px-6 dark:hover:text-blue-900 hover:text-blue-900 text-xs"
        to="/register"
      >
        Register
      </NavLink>
    </div>
  );
};
// const WhatssApp = () => {
//   return (
//     <button
//       className="fixed z-40 animate-bounce inset-x-0 lg:inset-x-auto bottom-6 lg:right-8 xl:right-10 xl:bottom-8 z-10bg-white   shadow-lg font-normal h-20 w-20 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
//       type="button"
//     >
//       <a
//         href="https://wa.me/+9495161062"
//         className="flex items-center flex-1 w-0"
//       >
//         <span className="flex  rounded-lg">
//           <img src={Logo} alt="logo" className="lg:h-10 " />
//         </span>
//       </a>
//     </button>
//   );
// };
const CallBtn = () => {
  return (
    <div className="w-auto fixed z-50 right-0 bottom-4 bg-transparent">
      <div className="flex flex-row relative">
        <div className="w-52 p-2 text-center bg-green-800  items-center justify-center align-middle hide">
          <span>+9495161062</span>
        </div>
        <div className="w-auto ">
          <div className="call mx-5 h-10 w-10 px-4 py-2 m-1 bg-green-800  shadow-lg flex items-center justify-center  rounded-full text-white hover:bg-green-400">
            <a href="https://wa.me/+9495161062">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 myDIV"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const NavBar = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  return (
    <>
      <div className="  h-10 w-full bg-blue-900"></div>
      <nav className="  top-10 z-50 h-10 drop-shadow-2xl right-0 left-0 px-8 mx-auto lg:flex lg:justify-between lg:items-center bg-white W-100">
        <div className="flex items-center justify-between">
          <div>
            <Link className="hover:scale-50" to="/">
              <img src={Logo} alt="logo" className="h-8" />
            </Link>
          </div>

          <div className="flex lg:hidden">
            <span
              type="button"
              className="text-black hover:animate-spin "
              aria-label="toggle menu"
              onClick={() => setMenuToggle((prevmenuToggle) => !prevmenuToggle)}
            >
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 297 297"
                xmlSpace="preserve"
                className="w-6 rotate-180"
              >
                <g>
                  <g>
                    <path
                      style={{ fill: `#60696D` }}
                      d="M53.437,212.083l-12.445,45.09C35.463,277.206,50.534,297,71.316,297h0
			c7.521,0,13.947-5.422,15.212-12.836l12.3-72.081H53.437z"
                    />
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#D0D5D9" }}
                      points="126.203,64.735 97.714,64.735 53.437,212.083 98.828,212.083 		"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#444E51" }}
                      d="M98.828,212.083l-11.414,66.889C85.806,288.396,93.066,297,102.626,297h30.613
			c8.523,0,15.432-6.909,15.432-15.432v-69.485H98.828z"
                    />
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#9EA7AD" }}
                      points="148.671,64.735 126.203,64.735 98.828,212.083 148.671,212.083 		"
                    />
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#D0D5D9" }}
                      points="172.102,64.735 148.671,64.735 148.671,212.083 198.421,212.083 		"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#60696D" }}
                      d="M148.671,212.083v69.485c0,8.523,6.909,15.432,15.432,15.432h30.079
			c9.52,0,16.77-8.536,15.229-17.93l-10.99-66.987H148.671z"
                    />
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#9EA7AD" }}
                      points="199.286,64.735 172.102,64.735 198.421,212.083 243.563,212.083 		"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#444E51" }}
                      d="M198.421,212.083l11.81,71.983c1.224,7.459,7.67,12.934,15.229,12.934h0.225
			c20.782,0,35.853-19.794,30.324-39.827l-12.445-45.09H198.421z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#B3BEC4" }}
                      d="M96.351,52.149C96.351,23.348,119.699,0,148.5,0s52.149,23.348,52.149,52.149H96.351z"
                    />
                  </g>
                  <g style={{ opacity: 0.66 }}>
                    <path
                      style={{ fill: "#B3BEC4" }}
                      d="M185.674,52.149h14.975C200.649,23.348,177.301,0,148.5,0c-2.918,0-5.776,0.251-8.563,0.712
			C164.447,6.018,183.227,26.692,185.674,52.149z"
                    />
                  </g>
                  <g>
                    <rect
                      x="96.351"
                      y="52.149"
                      style={{ fill: "#F86E51" }}
                      width="104.298"
                      height="17.383"
                    />
                  </g>
                  <g>
                    <rect
                      x="181.923"
                      y="52.149"
                      style={{ fill: "#DD4D36" }}
                      width="18.726"
                      height="17.383"
                    />
                  </g>
                  <g>
                    <rect
                      x="96.351"
                      y="52.149"
                      style={{ fill: "#DD4D36" }}
                      width="18.726"
                      height="17.383"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <div className=" hidden lg:block">
          <Menu />
        </div>
        <div
          className="flex lg:hidden bg-white  w-full shadow-lg  ease-in-out duration-300"
          onClick={() => setMenuToggle((prevmenuToggle) => !prevmenuToggle)}
        >
          {menuToggle ? <Menu /> : ""}
        </div>
      </nav>

      {/* <WhatssApp /> */}
      <CallBtn />
    </>
  );
};
export default NavBar;
