import { Link } from "react-router-dom";
// import booter from "../assets/badminton-footer.png";
import facebook from "../assets/facebbok.png";
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-600  bg-no-repeat bg-contain bg-top ">
        <div className="mx-auto grid max-w-screen-xl gap-y-8 gap-x-12 px-4 py-10 sm:px-20 md:grid-cols-2 xl:grid-cols-4 xl:px-10">
          <div className="max-w-sm">
            <div className="mb-6 flex h-10 items-center space-x-2">
              <img
                className="h-full object-contain shadow-lg bg-white"
                src={logo}
                alt=""
              />
              <span className="text-lg font-medium"></span>
            </div>
            <div className="text-gray-500">
              {/* The game has a rich history, originating from the game of
              battledore and shuttlecock in British India, and has evolved into
              a competitive sport that’s part of the Olympic Games since 19921 */}
            </div>
          </div>
          <div className="">
            <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-300 ">
              Guides
            </div>
            <nav aria-label="Guides Navigation" className="text-gray-300 ">
              <ul className="space-y-3">
                <li>
                  <a
                    className="hover:text-blue-600 hover:underline text-xs text-gray-200 "
                    href="/"
                  >
                    How to make a footer
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-blue-600 hover:underline text-xs text-gray-200 "
                    href="/"
                  >
                    Designing your app
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-blue-600 hover:underline text-xs text-gray-200 "
                    href="/"
                  >
                    Getting help from the community
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-blue-600 hover:underline text-gray-200  text-xs"
                    href="/"
                  >
                    Pricing vs Hourly Rate
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="">
            <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-100 ">
              Links
            </div>
            <nav
              aria-label="Footer Navigation "
              className="text-gray-500 flex flex-col"
            >
              <ul className="space-y-3">
                <li>
                  <Link
                    className="text-gray-200  text-xs lg:px-6 dark:hover:text-blue-400 hover:text-blue-500"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-gray-200 text-xs lg:px-6 dark:hover:text-red-400 hover:text-red-500"
                    to="/all-Categories"
                  >
                    Categorie's
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-gray-200 text-xs  lg:px-6 dark:hover:text-red-400 hover:text-red-500"
                    to="/all-clubs"
                  >
                    Club's
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-gray-200  text-xs lg:px-6 dark:hover:text-red-400 hover:text-red-500"
                    to="/register"
                  >
                    Register
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-gray-200 text-xs lg:px-6 dark:hover:text-red-400 hover:text-red-500"
                    to="/tournamnet-register"
                  >
                    Tournament
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="">
            <div className="mt-4 mb-2 font-medium xl:mb-4 text-gray-200 ">
              Support Us on Socialmedia
            </div>
            <div className="flex flex-col">
              <div className="mb-4">
                <a href="https://www.producthunt.com/products/Apple?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-Apple">
                  <img
                    src={facebook}
                    alt="Apple - Think&#0032;Different | Product Hunt"
                    // style={"width: 250px; height: 54px;"}
                    width="100"
                    height="54"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t">
          <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-gray-500 sm:px-20 lg:flex-row lg:justify-between lg:text-left xl:px-10">
            <p className="text-xs">© 2024| All Rights Reserved</p>
            <p className="-order-1 sm:order-none text-xs">
              Developed by drizzledesign | Wayanad | Web | Branding | Social
              Media
            </p>
            <p className="text-xs">
              <a className="text-xs" href="/">
                Privacy Policy
              </a>
              <span>|</span>
              <a className="text-xs" href="/">
                Terms of Service
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
