import Tournament from "../components/Tournamentcard";
import sectio3 from "../assets/bg4.jpg";
const Tournaments = () => {
  return (
    <section
      className=" bg-blue-900 bg-no-repeat bg-cover bg-top  min-h-screen overflow-hidden bg-blend-exclusion mb-5 shadow-2xl"
      style={{ backgroundImage: "url(" + sectio3 + ")" }}
    >
      <div className="container max-w-screen-xl align-middle pt-5 mx-auto flex  flex-col ">
        <div className="flex flex-col w-auto align-middle justify-center  p-4 mx-auto">
          <h1 className=" flex max-w-2xl mb-4 text-3xl font-extrabold tracking-tight align-middle justify-center leading-none md:text-4xl xl:text-5xl text-white">
            Upcoming Tournaments
          </h1>
          <p className="text-slate-300 flex w-full align-middle justify-center">
            Meet the Newly Promoted Players Making Waves in the Game
          </p>
        </div>
        <div className="flex flex-row w-full h-auto flex-wrap  align-middle justify-center pb-5">
          <Tournament />
          <Tournament />
          <Tournament />
          <Tournament />
        </div>
      </div>
    </section>
  );
};
export default Tournaments;
