import adde from "../assets/cover.jpg";
import sectio1 from "../assets/bg.jpg";
import sectio2 from "../assets/bg2.jpg";
import sectio3 from "../assets/bg4.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import Tournament from "../components/Tournamentcard";
const Home = () => {
  const [add, setAdd] = useState(true);
  return (
    <main
      className="bg-white   bg-cover bg-bottom  "
      // style={{ backgroundImage: "url(" + badmibton + ")" }}
    >
      {add ? (
        <section className="bg-yellow-300 max-w-screen-2xl   bg-no-repeat bg-cover  bg-top  fixed top-20 left-0 right-0 z-10 shadow-lg">
          <figure className="relative ">
            <div
              className="absolute top-5 right-5 lg:right-10 lg:top-10 "
              onClick={() => setAdd((prevadd) => !prevadd)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 hover:text-red-500 shadow-lg bg-fuchsia-50"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
            <Link
              className="text-gray-700  lg:px-6 dark:hover:text-red-400 hover:text-red-500"
              to="/register"
            >
              <img src={adde} alt="add" className="w-full h-auto " />
            </Link>
          </figure>
        </section>
      ) : (
        ""
      )}
      <section
        className="bg-white  bg-no-repeat bg-cover bg-center min-h-screen shadow-2xl mb-5"
        style={{ backgroundImage: "url(" + sectio1 + ")" }}
      >
        <div className="grid max-w-screen-xl align-middle pb-4 py-8 pt-20 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl ">
              <span className="text-red-600">Welcome to</span>{" "}
              <span className="text-blue-900">Badminton Wayanad</span>
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Whether you’re a player or a fan, badminton offers a dynamic and
              exciting experience!
            </p>
            <a
              href="/register"
              className="inline-flex bg-blue-900 rounded-full items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center   text-white transition-all duration-500  delay-75 hover:ease-out  hover:bg-red-500  focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              Refister now
              <svg
                className="w-5 h-5 ml-2 -mr-1 hover:animate-bounce"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section
        className=" bg-blue-900 bg-no-repeat bg-cover bg-top  min-h-screen overflow-hidden bg-blend-exclusion mb-5 shadow-2xl"
        style={{ backgroundImage: "url(" + sectio3 + ")" }}
      >
        <div className="container max-w-screen-xl align-middle pt-5 mx-auto flex  flex-col ">
          <div className="flex flex-col w-auto align-middle justify-center  p-4 mx-auto">
            <h1 className=" flex max-w-2xl mb-4 text-3xl font-extrabold tracking-tight align-middle justify-center leading-none md:text-4xl xl:text-5xl text-white">
              Upcoming Tournaments
            </h1>
            <p className="text-slate-300 flex w-full align-middle justify-center">
              Meet the Newly Promoted Players Making Waves in the Game
            </p>
          </div>
          <div className="flex flex-row w-full h-auto flex-wrap  align-middle justify-center pb-5">
            <Tournament />
            <Tournament />
            <Tournament />
            <Tournament />
          </div>
        </div>
      </section>
      <section
        className=" bg-blue-700 bg-no-repeat bg-cover bg-top  min-h-screen overflow-hidden bg-blend-luminosity mb-5 shadow-2xl"
        style={{ backgroundImage: "url(" + sectio2 + ")" }}
      >
        <div className="container max-w-screen-xl align-middle p-8 mx-auto flex  flex-col ">
          <div className="flex flex-col w-auto align-middle justify-center  p-4 mx-auto">
            <h1 className=" flex max-w-2xl mb-4 text-3xl font-extrabold tracking-tight align-middle justify-center leading-none md:text-4xl xl:text-5xl text-slate-600">
              Rising Stars
            </h1>
            <p className="text-slate-800">
              Meet the Newly Promoted Players Making Waves in the Game
            </p>
          </div>
          <div className="flex flex-row w-auto flex-wrap max-w-screen-lg">
            <Card />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
