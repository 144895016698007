import React from "react";
// import { Link } from "react-router-dom";

export const TournamentRegister = () => {
  return (
    <section className="container flex flex-col align-middle justify-center items-center mt-10">
      <h1 className="text-4xl font-extrabold text-red-600 mb-10">
        Register Tournament
      </h1>
      <div className="w-[750px] h-fit shadow-xl p-5 gap-4 flex flex-col bg-white mb-10">
        <h1 className="text-xl font-bold"> Tournament Register</h1>
        <form action="" className="">
          <select
            id="Select Tournament"
            class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5  "
          >
            <option selected>Select Tournament</option>
            <option value="A">
              Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament
              (U-15 & U-17)
            </option>
            <option value="B">
              Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament
              (U-18 & U-21)
            </option>
            <option value="C">
              Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament
              (U-21 & U-28)
            </option>
            <option value="M">
              Yonex- Sunrise All India Sub Junior Ranking Badminton Tournament
              (U-28 & U-45)
            </option>
          </select>
          <p className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6">
            Hyderabad, TS
          </p>
          <p className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6">
            10 Jun-17 Jun 2024
          </p>
          <p className="block w-full rounded-md border-0 py-1.5 mb-4 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6">
            Duels
          </p>
          <div className="flex flex-row">
            <select
              id="Select Tournament"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-1/2 p-2.5  "
              disabled
            >
              <option selected value="user">
                username
              </option>
            </select>
            <select
              id="Select Tournament"
              class="bg-gray-50 border border-gray-300 text-gray-900 mb-4 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-1/2 p-2.5 "
            >
              <option selected>select Companion</option>
              <option value="A">Raju</option>
              <option value="A">Ramu</option>
            </select>
          </div>
          <button
            type="button"
            class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 mt-4 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-1.5 text-center me-2 mb-2"
          >
            Register
          </button>
        </form>
        {/* <span>
          <Link
            to="/forgot-password"
            className="text-blue-900 hover:text-black"
          >
            forgot password ?
          </Link>
        </span> */}
      </div>
    </section>
  );
};
